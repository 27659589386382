import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Container, Row, Col } from 'react-bootstrap';

function App() {
  return (

    <Container fluid>

      <Row>
        <Col>
        <div className="App-header">Header</div>
        </Col>        
      </Row>

      <Row>
        <Col>Test1</Col>
        <Col xs={6}>  <div>
              Hello Randall
            </div>  </Col>
        <Col>Test2</Col>
      </Row>
      <Row>

      </Row>
    </Container>


  );
}

export default App;
